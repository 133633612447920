import InsurancesProductEnum from '@/enums/InsurancesProductEnum';
import { ADD_GA4_EVENT, ADD_GA4_IMMEDIATE_EVENT, ADD_GA4_PAGE_EVENTS } from '@/store/modules/trackingContext';
import store from '@/store/store';
import { stripProductIndex } from '@/views/calculators/services/ProductService';
import { STEP } from '@/views/calculators/services/UiStepService';

export const dataLayer = (window as any).dataLayer || [];
const selectableGroupIdTrackId = 'basket - beregn.ab.dk';
const selectableGroupIdName = 'basket - beregn.ab.dk';

type TrackProductItem = {
	item_id: string;
	item_name: string;
	affiliation: string;
	coupon: string;
	discount: number;
	index?: number;
	item_brand: string;
	item_category: string;
	item_category2?: string;
	item_category3?: string;
	item_variant: string;
	item_list_id?: string;
	item_list_name?: string;
	item_cart_type?: string;
	price: number;
	quantity: number;
	calcModelId?: string;
	insuranceTerminate?: boolean; // TODO KLK doesn't make sense if quantity is more than 1 or for family insurance
	insuranceActivationStart?: string; // TODO KLK doesn't make sense if quantity is more than 1 or for family insurance

	// custom variables
	// car, contents(home), house, holidayhouse, family of children
	excess?: number;
	// contents, family of children
	sumInsured?: number;
	// travel - europa/verden
	insuranceCoverage?: string;
	// accident
	workType?: string;
	// childAccident
	numberOfChildren?: number;
	// Dog
	numberOfDogs?: number;
	dogBreed?: string;
	gender?: string;
};

export const trackGeneralPageView = async (from: string, to: string, pathName: string): Promise<void> => {
	try {
		const commonModel = store.getters.getCalculatorCommonModel;
		const model = store.getters?.getActiveCalculator?.model;
		const basketModel = store.state.calculatorContext?.basket?.model;
		const email = await toSHA256(
			commonModel?.contact_information?.email ||
				basketModel?.contact_information?.email ||
				model?.contact_information?.email
		);
		const event = {
			userData: {
				userId: email,
				loginStatus: store.state?.authenticationContext?.authenticated,
				customerType: 'private',
			},
			pageSection: pathName,
			pagePlatform: 'alm. brand',
			previousPageUrl: from,
			fullUrl: to,
		};

		const event1 = {
			event: 'trackPageview',
		};
		await store.dispatch(ADD_GA4_PAGE_EVENTS, [event, event1]);
	} catch (e) {
		// too bad
		console.error(e);
	}
};

// Kald ved  produktsider
export const trackLoadInsurance = async (productName: string, stepName: string) => {
	try {
		const event = {
			event: 'trackInsuranceSelectionStart',
			insuranceSelectionType: stripProductIndex(productName),
			insuranceSelectionStepName: stepName,
		};

		await store.dispatch(ADD_GA4_EVENT, event);
	} catch (e) {
		// too bad
		console.error(e);
	}
};

// either begin_checkout or view_item
export const trackBeginCheckoutViewItem = async (productName: string, isCheckout: boolean) => {
	try {
		const items = getProductItems([productName], false, false, false);
		const event = {
			event: isCheckout ? 'begin_checkout' : 'view_item',
			ecommerce: {
				currency: 'DKK',
				value: 0,
				items,
			},
		};

		await store.dispatch(ADD_GA4_EVENT, eventReset);
		await store.dispatch(ADD_GA4_EVENT, event);
	} catch (e) {
		// too bad
		console.error(e);
	}
};

export const trackViewCard = async (listId: string, listName: string, cartType: string, immediate = false) => {
	try {
		const basket = store.getters.getSelectableGroup('basket');
		const items = getProductItems(basket, false, false, false, listId, listName, cartType);
		const event = {
			event: 'view_cart',
			ecommerce: {
				currency: 'DKK',
				value: 0,
				cart_type: cartType,
				items,
			},
		};

		if (immediate) {
			await store.dispatch(ADD_GA4_IMMEDIATE_EVENT, eventReset);
			await store.dispatch(ADD_GA4_IMMEDIATE_EVENT, event);
		} else {
			await store.dispatch(ADD_GA4_EVENT, eventReset);
			await store.dispatch(ADD_GA4_EVENT, event);
		}
	} catch (error) {
		console.error(error);
	}
};
// when the user is presented with select /forsikringer, basketWidget or /kurv->overview
export const trackViewItemList = async (listId: string, listName: string, useBasket: boolean, immediate = false) => {
	try {
		let products;
		if (useBasket) {
			products = store.getters.getSelectableGroup('basket');
		} else {
			products = [
				InsurancesProductEnum.BIL_FORSIKRING,
				InsurancesProductEnum.HUS_FORSIKRING,
				InsurancesProductEnum.FRITIDSHUS_FORSIKRING,
				InsurancesProductEnum.INDBO_FORSIKRING,
				InsurancesProductEnum.REJSE_FORSIKRING,
				InsurancesProductEnum.ULYKKES_FORSIKRING,
				InsurancesProductEnum.BOERNEULYKKES_FORSIKRING,
				InsurancesProductEnum.HUNDE_FORSIKRING,
				InsurancesProductEnum.BOERNE_FAMILIE_FORSIKRING,
			];
		}
		const items = getProductItems(products, true, true, true, listId, listName);
		const event = {
			event: 'view_item_list',
			ecommerce: {
				item_list_id: listId,
				item_list_name: listName,
				items,
			},
		};

		if (immediate) {
			await store.dispatch(ADD_GA4_IMMEDIATE_EVENT, eventReset);
			await store.dispatch(ADD_GA4_IMMEDIATE_EVENT, event);
		} else {
			await store.dispatch(ADD_GA4_EVENT, eventReset);
			await store.dispatch(ADD_GA4_EVENT, event);
		}
	} catch (error) {
		console.error(error);
	}
};

// Press "Start beregning" on /forsikringer page
export const trackSelectionGroupCta = async (groupId: string) => {
	try {
		const basket = store.getters.getSelectableGroup(groupId);
		const products: Array<string> = [];
		basket.forEach((productWithInx) => {
			const product = stripProductIndex(productWithInx).toLocaleLowerCase();
			products.push(product);
		});
		const items: TrackProductItem[] = getProductItems(basket, false, false, false);
		const event = {
			event: 'add_to_cart',
			ecommerce: {
				currency: 'DKK',
				value: 0,
				items,
			},
		};

		const event2 = {
			event: 'trackInsuranceSelectionInitiated',
			insuranceSelectionType: sortStringArray(products),
		};

		await store.dispatch(ADD_GA4_IMMEDIATE_EVENT, eventReset);
		await store.dispatch(ADD_GA4_IMMEDIATE_EVENT, event);
		await store.dispatch(ADD_GA4_IMMEDIATE_EVENT, event2);
	} catch (e) {
		// too bad
		console.error(e);
	}
};

// immediate
export const trackAddRemoveProduct = async (productName: string, add: boolean) => {
	try {
		const items = getProductItems([productName], false, false, false);
		let event;
		if (!add) {
			event = {
				event: 'remove_from_cart',
				ecommerce: {
					currency: 'DKK',
					value: 0,
					items,
				},
			};
		} else {
			(items[0].index = 0),
				(items[0].item_list_id = selectableGroupIdTrackId),
				(items[0].item_list_name = selectableGroupIdName),
				(event = {
					event: 'select_item',
					ecommerce: {
						item_list_id: selectableGroupIdTrackId,
						item_list_name: selectableGroupIdName,
						currency: 'DKK',
						value: 0,
						items,
					},
				});
		}
		await store.dispatch(ADD_GA4_IMMEDIATE_EVENT, eventReset);
		await store.dispatch(ADD_GA4_IMMEDIATE_EVENT, event);
	} catch (e) {
		// too bad
		console.error(e);
	}
};

export const trackProductStep = async (productName: string, stepName: string): Promise<void> => {
	try {
		switch (stepName) {
			case STEP.PACKAGE:
				await trackBeginCheckoutViewItem(productName, false);
				break;
			case STEP.PAYMENT:
				await trackAddPaymentInfo();
				break;
			case STEP.CONTACT_INFORMATION:
				await trackShippingInfo();
				break;
			default:
				break;
		}

		const event = {
			event: 'trackInsuranceSelectionProgress',
			insuranceSelectionType: stripProductIndex(productName),
			insuranceSelectionStepName: stepName,
		};

		await store.dispatch(ADD_GA4_EVENT, event);
	} catch (e) {
		// too bad
		console.error(e);
	}
};

const trackShippingInfo = async () => {
	try {
		const basket = store.getters.getSelectableGroup('basket');

		const items: Array<TrackProductItem> = getProductItems(basket, false, false, false);
		let yearlyPriceTotalAllProducts = 0;
		items.forEach((product) => {
			yearlyPriceTotalAllProducts += product.price;
		});
		const event = {
			event: 'add_shipping_info',
			ecommerce: {
				currency: 'DKK',
				value: yearlyPriceTotalAllProducts,
				items,
			},
		};

		await store.dispatch(ADD_GA4_EVENT, eventReset);
		await store.dispatch(ADD_GA4_EVENT, event);
	} catch (e) {
		// too bad
		console.error(e);
	}
};

const trackAddPaymentInfo = async () => {
	try {
		const basket = store.getters.getSelectableGroup('basket');
		const items: Array<TrackProductItem> = getProductItems(basket, false, false, false);
		let yearlyPriceTotalAllProducts = 0;
		items.forEach((product) => {
			yearlyPriceTotalAllProducts += product.price;
		});

		const event = {
			event: 'add_payment_info',
			ecommerce: {
				currency: 'DKK',
				value: yearlyPriceTotalAllProducts,
				payment_type: 'Bank account',
				items,
			},
		};

		await store.dispatch(ADD_GA4_EVENT, eventReset);
		await store.dispatch(ADD_GA4_EVENT, event);
	} catch (e) {
		// too bad
		console.error(e);
	}
};

export const trackPurchase = async () => {
	try {
		const basket = store.getters.getSelectableGroup('basket');

		if (!basket) {
			return;
		}
		const items: Array<TrackProductItem> = getProductItems(basket, false, false, false);
		let yearlyPriceTotalAllProducts = 0;
		let yearlyStatutoryFeeAllProducts = 0;
		items.forEach((product) => {
			yearlyPriceTotalAllProducts += product.price || 0;
			const pModel = store.getters.getCalculator(product.calcModelId)?.model;
			const choosePackage = pModel?.choosePackage;
			yearlyStatutoryFeeAllProducts += choosePackage?.selectedPackage?.yearlyStatutoryFee || 0;
			// if (product.quantity === 1) {

			if (pModel) {
				// TODO KLK this doesn't work for family insurance
				product.insuranceTerminate = pModel.additionalInfo.existInsurance === 'ja';
				product.insuranceActivationStart =
					pModel.additionalInfo.validNow === 'ja'
						? 'as soon as possible'
						: pModel.additionalInfo.validFromDate;

				// accident
				if (pModel.personInfo.work) {
					product.workType = pModel.personInfo.work;
				}

				// home, family of children
				if (choosePackage?.insuredValueAmount) {
					product.sumInsured = choosePackage.insuredValueAmount;
				}
				// car, contents(home), house, holidayhouse, family of children
				if (choosePackage?.ownRiskId > 0) {
					const excess = pModel.calculation.abCalc.excesses.filter(
						(excess) => excess.id === choosePackage.ownRiskId
					);

					if (excess) {
						product.excess = excess[0].amount;
					}
				}

				if (pModel.dogInfo) {
					product.numberOfDogs = pModel.dogInfo.dogCount || 0;
					let breed = 'NA';
					if (pModel.dogInfo.dogType1) {
						breed = pModel.dogInfo.dogType1;
					}
					if (pModel.dogInfo.dogCount > 1 && pModel.dogInfo.dogType2) {
						breed += ', ' + pModel.dogInfo.dogType2;
					}
					let gender = 'NA';
					if (pModel.dogInfo.gender1) {
						gender = pModel.dogInfo.gender1;
					}
					if (pModel.dogInfo.dogCount > 1 && pModel.dogInfo.gender2) {
						gender += ', ' + pModel.dogInfo.gender2;
					}
					product.dogBreed = breed;
					product.gender = gender;
				}

				// travel
				if (choosePackage?.segmentedProductProperty) {
					product.insuranceCoverage = choosePackage.segmentedProductProperty;
				}

				// family
				if (pModel?.familyInfo?.personCountId >= 0) {
					product.numberOfChildren = pModel?.familyInfo?.personCountId + 1; // one child = 0 in personCountId;
				}
				// cleanup ProductItem
				product.calcModelId = undefined;
			}
			// }
		});

		const commonModel = store.getters.getCalculatorCommonModel;
		const model = store.getters.getActiveCalculator.model;
		const basketModel = store.state.calculatorContext?.basket?.model;
		const monthly = store.getters.getBasket?.model?.choosePackage?.monthYear || model.choosePackage.monthYear;

		const fullName = (
			commonModel?.contact_information?.name ||
			basketModel?.contact_information?.name ||
			model.contact_information.name
		).trim();
		const nameArray = fullName.split(' ');
		let firstName = nameArray[0];
		const lastName = nameArray.length > 1 ? nameArray[nameArray.length - 1] : 'NA';
		if (lastName !== 'NA') {
			firstName = fullName.replaceAll(' ' + lastName, '');
		}

		const event = {
			event: 'purchase',
			userData: {
				userId: await toSHA256(
					commonModel?.contact_information?.email ||
						basketModel?.contact_information?.email ||
						model.contact_information.email
				),
				phoneNumber: await toSHA256(
					E164format(
						commonModel?.contact_information?.phone ||
							basketModel?.contact_information?.phone ||
							model.contact_information.phone
					)
				),
				city: await toSHA256(
					commonModel?.personInfo?.zipName || basketModel?.personInfo?.zipName || model.personInfo.zipName
				),
				street: await toSHA256(
					commonModel?.personInfo?.streetName ||
						basketModel?.personInfo?.streetName ||
						model.personInfo.streetName
				),
				country: await toSHA256('DK'),
				postCode: await toSHA256(
					commonModel?.personInfo?.zipCode || basketModel?.personInfo?.zipCode || model.personInfo.zipCode
				),
				//'region': '<Insert user region>',
				firstname: await toSHA256(firstName),
				lastname: await toSHA256(lastName),
			},
			insuranceData: {
				insuranceRegistered:
					(commonModel.personInfo.almbrandCustomer ||
						basketModel?.personInfo?.almbrandCustomer ||
						model.personInfo.almbrandCustomer) === 'ja',
				insurancePeopleInHousehold:
					commonModel?.personInfo?.familyMemberCountId ||
					basketModel?.personInfo?.familyMemberCountId ||
					model.personInfo.familyMemberCountId,
				insuranceAge:
					commonModel?.personInfo?.customerAge ||
					basketModel?.personInfo?.customerAge ||
					model.personInfo.customerAge,
				insuranceClaimsNumber:
					commonModel?.additionalInfo?.skader ||
					basketModel?.contact_information?.skader ||
					model.additionalInfo.skader,
				insurancePaymentRate: monthly === 'M' ? 'Monthly' : 'Yearly',
			},
			ecommerce: {
				transaction_id: Date.now() + '',
				currency: 'DKK',
				tax: yearlyStatutoryFeeAllProducts,
				value: yearlyPriceTotalAllProducts,
				payment_type: 'Bank account',
				items,
			},
		};

		await store.dispatch(ADD_GA4_EVENT, eventReset);
		await store.dispatch(ADD_GA4_EVENT, event);
	} catch (e) {
		// too bad
		console.error(e);
	}
};

export const trackReceipt = async () => {
	try {
		const basket = store.getters.getSelectableGroup('basket');

		let name = 'kurv';
		if (basket.length === 1) {
			name = stripProductIndex(basket[0]);
		}
		const event = {
			event: 'trackInsuranceSelectionComplete',
			insuranceSelectionType: name,
			insuranceSelectionStepName: 'order',
		};

		await store.dispatch(ADD_GA4_EVENT, event);
	} catch (e) {
		// too bad
		console.error(e);
	}
};

// immediate
export const trackContact = async () => {
	try {
		const event = {
			event: 'trackFormStart',
			formAction: 'form submit',
			formName: 'Calculators contact Almbrand',
		};
		await store.dispatch(ADD_GA4_IMMEDIATE_EVENT, event);
	} catch (e) {
		// too bad
		console.error(e);
	}
};

// immediate
export const trackError = async (errorType: string, errorCode = 'NA') => {
	try {
		const event = {
			event: 'trackError',
			errorType,
			errorCode,
		};

		await store.dispatch(ADD_GA4_IMMEDIATE_EVENT, event);
	} catch (e) {
		// too bad
		console.error(e);
	}
};

// immediate
export const trackInsuranceSelectionNote = async (
	productName: string,
	stepName: string,
	msg: string,
	description?: string
) => {
	try {
		const event = {
			event: 'trackInsuranceSelectionNote',
			insuranceSelectionType: productName,
			insuranceSelectionStepName: stepName,
			insuranceSelectionNoteType: msg,
			insuranceSelectionNoteDescription: description || msg,
		};

		await store.dispatch(ADD_GA4_IMMEDIATE_EVENT, event);
	} catch (e) {
		// too bad
		console.error(e);
	}
};

// Private methods
const eventReset = { ecommerce: null };
const getTrackCategory2 = (name: string) => {
	switch (name) {
		case InsurancesProductEnum.BIL_FORSIKRING:
			return 'transport';
		case InsurancesProductEnum.HUS_FORSIKRING: // fallthrough
		case InsurancesProductEnum.FRITIDSHUS_FORSIKRING: // fallthrough
		case InsurancesProductEnum.INDBO_FORSIKRING:
			return 'housing';
		case InsurancesProductEnum.REJSE_FORSIKRING:
			return 'travel';
		case InsurancesProductEnum.ULYKKES_FORSIKRING:
			return 'health';
		case InsurancesProductEnum.BOERNEULYKKES_FORSIKRING:
			return 'health';
		case InsurancesProductEnum.HUNDE_FORSIKRING:
			return 'health';
		case InsurancesProductEnum.BOERNE_FAMILIE_FORSIKRING:
			return 'health, housing';
		default:
			return name;
	}
};

const getTrackCategory3 = (name: string) => {
	switch (name) {
		case InsurancesProductEnum.BIL_FORSIKRING:
			return 'car';
		case InsurancesProductEnum.HUS_FORSIKRING:
			return 'house';
		case InsurancesProductEnum.FRITIDSHUS_FORSIKRING:
			return 'holidayhouse';
		case InsurancesProductEnum.INDBO_FORSIKRING:
			return 'home';
		case InsurancesProductEnum.REJSE_FORSIKRING:
			return 'travel';
		case InsurancesProductEnum.ULYKKES_FORSIKRING:
			return 'accident';
		case InsurancesProductEnum.BOERNEULYKKES_FORSIKRING:
			return 'childaccident';
		case InsurancesProductEnum.HUNDE_FORSIKRING:
			return 'dog';
		case InsurancesProductEnum.BOERNE_FAMILIE_FORSIKRING:
			return 'health, house';
		default:
			return name;
	}
};

const getProductItems = (
	basket: Array<string>,
	includeIndex: boolean,
	includeItemListId: boolean,
	includeItemListName: boolean,
	listId?: string,
	listName?: string,
	cartType?: string
): Array<TrackProductItem> => {
	//const multiples = new Map<string, number>();
	//const multiplePrices = new Map<string, number>();
	// basket.forEach((productWithInx) => {
	//     const calculator = store.getters.getCalculator(productWithInx);
	//     const addPrice = calculator?.model?.choosePackage?.selectedPackage?.yearlyPriceTotal || 0;
	//     const product = stripProductIndex(productWithInx);
	//     const amount = multiples.get(product);
	//     if (amount) {
	//         multiples.set(product, amount + 1);
	//         const price = multiplePrices.get(product);
	//         multiplePrices.set(product, price + addPrice);

	//     } else {
	//         multiples.set(product, 1);
	//         multiplePrices.set(product, addPrice);
	//     }
	// });
	const items: TrackProductItem[] = [];
	let index = 0;
	//multiples.forEach((quantity, product) => {
	basket.forEach((productWithInx) => {
		const calculator = store.getters.getCalculator(productWithInx);
		const productName = stripProductIndex(productWithInx).toLocaleLowerCase();
		const item: TrackProductItem = {
			item_id: productName,
			item_name: productName,
			affiliation: 'online',
			coupon: 'one insurance - 15% discount',
			discount: 0,
			// index,
			item_brand: 'alm. brand',
			item_category: 'insurance',
			item_category2: getTrackCategory2(productName),
			item_category3: getTrackCategory3(productName),
			item_variant: 'basic coverage',
			// item_list_id: selectableGroupIdTrackId,
			// item_list_name: selectableGroupIdName,
			price: calculator?.model?.choosePackage?.selectedPackage?.yearlyPriceTotal || 0,
			quantity: 1,
			calcModelId: productWithInx,
		};
		if (cartType) {
			item.item_cart_type = cartType;
		}
		if (includeIndex) {
			item.index = index;
		}
		if (includeItemListId) {
			item.item_list_id = listId || selectableGroupIdTrackId;
		}
		if (includeItemListName) {
			item.item_list_name = listName || selectableGroupIdName;
		}
		items.push(item);
		index++;
	});
	return items;
};

const sortStringArray = (stringArray: Array<string>): Array<string> => {
	return stringArray.sort((a, b) => a.localeCompare(b));
};
const E164format = (phoneNo) => {
	if (!phoneNo) {
		return '';
	}
	const cleanPhone = phoneNo.trim().replaceAll(' ', '');
	if (!cleanPhone.startsWith('+45')) {
		return '+45' + cleanPhone;
	}
	return cleanPhone;
};
const toSHA256 = async (message): Promise<string> => {
	if (!message) {
		return undefined;
	}
	// return message;
	const msgUint8 = new TextEncoder().encode(message); // encode as (utf-8) Uint8Array
	const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8); // hash the message
	const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
	const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join(''); // convert bytes to hex string
	return hashHex;
};
