import Axios, { AxiosRequestConfig } from 'axios';
import AxiosRetry from 'axios-retry';
import { getReCaptchaToken } from './reCaptchaService';

const timeout = 15000;

export default class AxiosService {
	private axiosRetry = AxiosRetry;

	protected axiosRetry3 = Axios.create({
		timeout,
		headers: {
			// 'Accept': 'application/json',
			Accept: '*/*',
			'Accept-Language': 'da',
		},
	});
	private axiosOnce = Axios.create({
		timeout,
		headers: {
			//'Accept': 'application/json',
			Accept: '*/*',
			'Accept-Language': 'da',
		},
	});
	protected defaultUrl: string;
	private withCredentials = true;
	// Recaptcha DISABLED
	private recaptchaEnabled = false;

	private retryConfig = {
		retries: 2,
		retryDelay: (retryCount: number) => {
			return retryCount * 100; // time interval between retries
		},
		retryCondition: (error) => {
			if (!error?.response?.status) {
				return true;
			}
			return error?.response?.status > 240;
		},
		shouldResetTimeout: true,
	};

	constructor(url?: string) {
		this.defaultUrl = url;

		this.axiosRetry(this.axiosRetry3, this.retryConfig);

		this.axiosRetry3.interceptors.request.use(async (config) => {
			return await this.addHeaders(config);
		});

		this.axiosOnce.interceptors.request.use(async (config) => {
			return await this.addHeaders(config);
		});
	}

	public setWithCredentials(withCredentials: boolean) {
		this.withCredentials = withCredentials;
	}

	public useRecaptcha(useRecaptcha: boolean) {
		// !Recaptcha Disabled
		//this.recaptchaEnabled = useRecaptcha;
		return false;
	}

	public async getQuery(url: string, params): Promise<any> {
		const url_ = url ? url : this.defaultUrl;
		try {
			return await this.axiosOnce.get(url_, this.buildGetParams(params));
		} catch (error) {
			console.error(error);
			if (error?.response) {
				return error.response;
			}
		}
	}

	public async get(url: string = this.defaultUrl): Promise<any> {
		try {
			return await this.axiosOnce.get(url, this.buildGetParams(undefined));
		} catch (error) {
			console.error(error);
			if (error?.response) {
				return error.response;
			}
		}
	}
	public getRetryDefaultUrl(params?: any): Promise<any> {
		return this.getRetry(undefined, params);
	}

	// private count = 1;
	public async getRetry(url: string, params?: any): Promise<any> {
		let response;
		try {
			const url_ = url ? url : this.defaultUrl;
			response = await this.axiosRetry3.get(url_, this.buildGetParams(params));
			// const calcName = 'travel';
			// if(!url && this.defaultUrl.includes(calcName) && this.count%5 === 0) {
			//     console.log('hooked ',calcName, this.count);
			//     this.count++;
			//     await new Promise( (reg) => setTimeout(reg, 2000));
			//     return {
			//         data: {},
			//         status: 200
			//     }
			// }
			// this.count++;
			return response;
		} catch (error) {
			console.error(error);
			if (error?.response) {
				return error.response;
			}
		}
	}

	/** default url is used if Url param is undefined */
	public async post(url: string, params?: any): Promise<any> {
		try {
			const url_ = url ? url : this.defaultUrl;
			const response = await this.axiosOnce.post(url_, this.buildPostParams(params));

			// if(!url && url_.includes('accident') && this.count%3 === 0) {
			//     console.log('hooked accident', this.count);
			//     this.count++;
			//     await new Promise( (reg) => setTimeout(reg, 2000));
			//     return {
			//         data: {},
			//         status: 200
			//     }
			// }
			// this.count++;
			return response;
		} catch (error) {
			console.error(error);

			if (error?.response) {
				return error.response;
			}
		}
	}

	/** default url is used if Url param is undefined */
	public async postRetry(url: string, params?: any): Promise<any> {
		try {
			const url_ = url ? url : this.defaultUrl;
			const response = await this.axiosRetry3.post(url_, this.buildPostParams(params));
			return response;
		} catch (error) {
			console.error(error);

			if (error?.response) {
				return error.response;
			}
		}
	}

	public async delete(url: string, params?: any): Promise<any> {
		try {
			const url_ = url ? url : this.defaultUrl;
			return await this.axiosOnce.delete(url_, this.buildPostParams(params));
		} catch (error) {
			console.error(error);
			if (error?.response) {
				return error.response;
			}
		}
	}

	public async put(url: string, params?: any): Promise<any> {
		try {
			const url_ = url ? url : this.defaultUrl;
			return await this.axiosOnce.put(url_, this.buildPostParams(params));
		} catch (error) {
			console.error(error);

			if (error?.response) {
				return error.response;
			}
		}
	}

	private async addHeaders(config): Promise<AxiosRequestConfig> {
		let recaptchaToken: string;
		// console.log('add headers - recaptchaEnabled', this.recaptchaEnabled);

		if (this.recaptchaEnabled) {
			recaptchaToken = await getReCaptchaToken();
		}
		// console.log('token', recaptchaToken);

		config.headers = {
			'x-recaptcha-token': recaptchaToken,
			Accept: 'application/json',
			'Accept-Language': 'da',
		};
		return config;
	}

	private buildGetParams(params) {
		return Object.assign({}, { params }, { timeout, withCredentials: this.withCredentials });
	}

	private buildPostParams(params) {
		return Object.assign({}, params, {
			timeout,
			withCredentials: this.withCredentials,
		});
	}
}
