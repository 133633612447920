export default class Coverage {
	public featureId: string;
	public name: string;
	public title: string;
	public description: string;
	public selected?: boolean;

	public constructor(featureId: string, name: string, title: string, description: string) {
		this.featureId = featureId;
		this.name = name;
		this.title = title;
		this.description = description;
		this.selected = false;
	}
}
