import { SelectableData, UPDATE_SELECTABLE, SELECTABLE_UPDATED } from '@/store/modules/selectableContext';
import { DISCOUNT_UPDATE, RESET_CALCULATORS } from '@/store/modules/calculatorContext';
import InsurancesProductEnum from '@/enums/InsurancesProductEnum';
import { Options, Vue } from 'vue-class-component';
import store from '@/store/store';
import {
	getDiscount,
	getDiscountForAmountProducts,
	handleSpecialCarDiscounts,
	resetSelectedCalculation,
} from '../services/CalculationService';
import { Formatter } from '../services/ValidationService';
import { setHighLights } from '../services/ProductService';
import { path } from '../services/NavigationService';
import OverviewBasketMiniStepComponent from '../basket/steps/OverviewBasketMiniStepComponent.vue';
import PopoverComponent from '@/sharedcomponents/baseComponents/popoverComponent/popoverComponent';
import { trackViewCard } from '@/services/trackingUtility';

@Options({
	name: 'BasketWidgetComponent',
	components: {
		OverviewBasketMiniStepComponent,
	},
	props: {
		productName: { type: String, default: undefined }, // productname for tracking and Basket
		showBasket: { type: Boolean, default: true },
		forceOpen: { type: Boolean, default: false },
	},
})
export default class BasketWidgetComponent extends Vue {
	productName?: string; // productname for tracking and Basket
	showBasket?: boolean;
	forceOpen?: boolean;

	public basket: Array<string> = [];
	public productsInBasket = 0;
	private unsubscribe: Function;
	public discount = '';

	public beforeUnmount() {
		this.unsubscribe();
	}

	public async mounted() {
		// make sure current product is in basket
		const uniqueBasket = store.getters.getSelectableGroupUnique('basket');
		if (this.productName && !uniqueBasket.includes(this.productName)) {
			const toggle: SelectableData = {
				group: 'basket',
				id: this.productName + '0',
				isSelected: true,
				sortMultiple: true,
			};
			await store.dispatch(UPDATE_SELECTABLE, toggle);
		}

		this.setBasket();

		this.unsubscribe = store.subscribeAction((action, state) => {
			if (action.type === SELECTABLE_UPDATED && action.payload?.group === 'basket') {
				this.setBasket();
				this.setDiscountUi();
			} else if (action.type === RESET_CALCULATORS) {
				this.discount = '';
			} else if (action.type === DISCOUNT_UPDATE) {
				this.setDiscountUi();
			}
		});
		this.setDiscountUi();
	}

	public get showContent(): boolean {
		return this.forceOpen || this.productName !== undefined;
	}

	public setDiscountUi() {
		this.discount = '';
		const cms = store.getters.getActiveCalculator?.cms || store.getters.getBasket?.cms;
		if (cms) {
			const discount = getDiscount(cms);
			if (discount.discountDisplay) {
				this.discount = cms.miniBasketDiscountPreTxt + discount.discountDisplay + cms.miniBasketDiscountPostTxt;
			}
		}
		this.handleDiscountChange();
	}

	private setBasket(): void {
		this.basket = store.getters.getSelectableGroup('basket');
		this.productsInBasket = this.basket.length;
	}

	public track() {
		setTimeout(() => {
			// wait for popup to open/close
			if ((this.$refs.popover as PopoverComponent).isOpen) {
				const idName = 'popup_basket';
				const cardType = 'cart popover';
				trackViewCard(idName, idName, cardType, true);
				//.then( () => trackViewItemList(idName, idName, true, true));
			}
		}, 250);
	}

	public get forsikringerLink() {
		//const url = urlService.ensureCorrectLink(path + 'forsikringer');
		// return url;
		return path + 'forsikringer';
	}

	private handleDiscountChange() {
		const cms = store.getters.getActiveCalculator?.cms || store.getters.getBasket?.cms;
		const rebate = getDiscount(cms);
		const basket = store.getters.getSelectableGroup('basket');

		if (store.getters.getBasket?.model) {
			const model = store.getters.getBasket?.model;
			if (rebate.discount !== model.calculation.discount) {
				model.calculation.discount = rebate.discount;
				model.calculation.discountDisplay = rebate.discountDisplay;
			}
		}

		basket.forEach((product) => {
			const calc = store.getters.getCalculator(product);
			if (calc) {
				let update = false;
				if (calc.model.campaign.valid && calc.model.campaign.productStepDiscount) {
					// possible change of campaign discount
					const calculatedDiscount = getDiscountForAmountProducts(calc.model.campaign);
					if (calc.model.campaign.discount !== calculatedDiscount) {
						calc.model.campaign.discount = calculatedDiscount;
						calc.model.campaign.originalDiscount = calc.model.campaign.discount;
						calc.model.campaign.discountDisplay =
							Formatter.format(100 * calc.model.campaign.discount) + ' %';
						update = true;
					}
				}

				if (calc.model.choosePackage.selectedPackage) {
					if (update || calc.model.calculation.discount !== rebate.discount) {
						calc.model.overview.highlights = undefined;
						this.changeDiscountOnCalculation(calc, rebate);
					}
				}
			}
		});
	}

	private changeDiscountOnCalculation(calculation: any, rebate: { discount: number; discountDisplay: string }) {
		const selectedPackage = calculation.model.choosePackage.selectedPackage;
		if (
			calculation.model.productName === InsurancesProductEnum.HUNDE_FORSIKRING &&
			selectedPackage.id === calculation.cms.sickAccidentpackageId
		) {
			if (!calculation.cms?.alwaysUseSickAccident && rebate.discount === 1) {
				// not pluscustomer - remove package
				resetSelectedCalculation(calculation.model);
				// TODO KLK en popup, der forklare at dækningen ikke kan købes
				return;
			}
			const discount = calculation.model.calculation.discount;
			if (discount >= 0.95 && rebate.discount < 0.95) {
				// .9 | .85
				selectedPackage.basePrice = selectedPackage.basePrice - selectedPackage.specialFee;
				selectedPackage.yearlyBasePrice = selectedPackage.yearlyBasePrice - selectedPackage.yearlySpecialFee;
			}

			if (rebate.discount < 0.95 && discount >= 0.95) {
				// .9 | .85
				selectedPackage.basePrice = selectedPackage.basePrice + selectedPackage.specialFee;
				selectedPackage.yearlyBasePrice = selectedPackage.yearlyBasePrice + selectedPackage.yearlySpecialFee;
			}
		}

		calculation.model.calculation.discount = rebate.discount;
		calculation.model.calculation.discountDisplay = rebate.discountDisplay;

		// handle campaigns including pluscustomer discount
		this.handleIncludeDiscountPlusCustomer(calculation);

		const special = handleSpecialCarDiscounts(calculation.model, calculation.cms);
		selectedPackage.specialDiscount = special.specialDiscount;

		// monthly
		let totalPrice;
		let statutoryFee;
		statutoryFee = selectedPackage.statutoryFee;
		totalPrice = selectedPackage.basePrice - statutoryFee;

		// pluscustomer discount
		// totalPrice *= calculation.model.calculation.discount;
		let discount = calculation.model.calculation.discount;

		if (calculation.model.campaign.valid) {
			discount -= calculation.model.campaign.discount;
		}

		totalPrice *= discount;

		// special discount
		if (selectedPackage.specialDiscount) {
			totalPrice -= totalPrice * selectedPackage.specialDiscount;
		}
		// // campaign discount
		// if (calculation.model.campaign.valid)  {
		//     totalPrice -= (totalPrice * calculation.model.campaign.discount);
		// }

		selectedPackage.totalPrice = Math.round(totalPrice + statutoryFee);
		selectedPackage.totalPriceDisplay = Formatter.format(selectedPackage.totalPrice) + ' kr.';

		// Yearly
		statutoryFee = selectedPackage.yearlyStatutoryFee;
		totalPrice = selectedPackage.yearlyBasePrice - statutoryFee;

		// plusCustomer discount
		//totalPrice *= calculation.model.calculation.discount;
		discount = calculation.model.calculation.discount;

		if (calculation.model.campaign.valid) {
			discount -= calculation.model.campaign.discount;
		}

		totalPrice *= discount;

		// special discount
		if (selectedPackage.specialDiscount) {
			totalPrice -= totalPrice * selectedPackage.specialDiscount;
		}
		// // campaign discount
		// if (calculation.model.campaign.valid)  {
		//     totalPrice -= (totalPrice * calculation.model.campaign.discount);
		// }

		selectedPackage.yearlyPriceTotal = Math.round(totalPrice + statutoryFee);
		selectedPackage.yearlyPriceTotalDisplay = Formatter.format(selectedPackage.yearlyPriceTotal) + ' kr.';
		setHighLights(calculation.model);
	}

	private handleIncludeDiscountPlusCustomer(calculation) {
		// TODO KLK handle TIA discount
		if (calculation.model.campaign.valid && calculation.model.campaign.includeDiscountPlusCustomer) {
			calculation.model.campaign.discount =
				calculation.model.campaign.originalDiscount + (calculation.model.calculation.discount - 1);
			calculation.model.campaign.discountDisplay =
				Formatter.format(100 * calculation.model.campaign.discount) + ' %';
		}
	}
}
