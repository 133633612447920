import { Options, Vue } from 'vue-class-component';
import VueScrollTo from 'vue-scrollto';
import { mapState } from 'vuex';
import { CardBlock, HtmlBlock, UsabillaInPageBlock } from '@/cms/definitions/content-types';
import UsabillaInPageComponent from '@/sharedcomponents/pageComponents/usabillaInPageComponent/UsabillaInPageComponent.vue';
import { BasketSettings } from './BasketSettings';
import { Model, STEPS } from './Model';
import ReceiptStepComponent from '../commonSteps/ReceiptStepComponent.vue';
import { BasketProductsEnum, BASKET_LOADED, DISCOUNT_UPDATE, UPDATE_BASKET } from '@/store/modules/calculatorContext';
import CampaignStepComponent from '../commonSteps/CampaignStepComponent.vue';
import PaymentBasketStepComponent from './steps/PaymentBasketStepComponent.vue';
import OverviewBasketStepComponent from './steps/OverviewBasketStepComponent.vue';
import ContactInformationBasketStepComponent from './steps/ContactInformationBasketStepComponent.vue';
import InsurancesProductEnum from '@/enums/InsurancesProductEnum';
import { cloneDeep } from 'lodash';
import store from '@/store/store';
import { PropType } from 'vue';
import { handlePreselectedProducts, nextStep } from '../services/NavigationService';
import { getDiscount } from '../services/CalculationService';
import {
	Formatter,
	getShowReceipt,
	isValidContactInfo,
	isValidPaymentInfo,
	monthYearArray,
	Validator,
	ValuePair,
} from '../services/ValidationService';
import { getSubtitle } from '../services/ProductService';
import { initComponent, STEP } from '../services/UiStepService';
import MapOrderDescription from './MapOrderDescription';

@Options({
	name: 'BasketComponent',
	props: {
		contentBlocks: Object as PropType<Array<CardBlock>>,
		cardReceipt: Object as PropType<CardBlock>,
		settingsBlock: Object as PropType<HtmlBlock>,
		usabillaBlock: Object as PropType<UsabillaInPageBlock>,
		contentUrl: String, // Used by service to handle browser history
		headerBadgeTxt: String,
		hasBadgeText: Boolean,
	},
	components: {
		UsabillaInPageComponent: UsabillaInPageComponent,
		ReceiptStepComponent,
		CampaignStepComponent,
		OverviewBasketStepComponent,
		ContactInformationBasketStepComponent,
		PaymentBasketStepComponent,
	},
	computed: mapState<any>({
		model: (state) => state.calculatorContext.basket.model,
		cms: (state) => state.calculatorContext.basket.cms,
	}),
})
export default class BasketComponent extends Vue {
	contentBlocks: Array<CardBlock>;
	cardReceipt: CardBlock;
	settingsBlock: HtmlBlock;
	usabillaBlock: UsabillaInPageBlock;
	contentUrl: string; // Used by service to handle browser history
	headerBadgeTxt: string;
	hasBadgeText: boolean;

	// list and order of steps
	public steps: Array<string> = STEPS;

	public model!: any; // model (store)
	public cms!: BasketSettings; // settings (store)
	public monthYearArray: Array<ValuePair> = monthYearArray;
	public componentInit = false;
	public checkout = false;
	private mapOrderDescription: MapOrderDescription;

	public async created() {
		handlePreselectedProducts(this.$route.query);
		if (!store.getters.getBasket) {
			// this is first load - update store
			const cms = new BasketSettings(this.settingsBlock);
			await store.dispatch(UPDATE_BASKET, {
				cms,
				model: cloneDeep(Model.model),
			});
			// check for contact_information
			const basket = store.getters.getSelectableGroup('basket');
			basket.forEach((product) => {
				const calc = store.getters.getCalculator(product);
				if (calc && calc.model?.contact_information?.customerNo) {
					this.model.contact_information.customerNo = calc.model.contact_information.customerNo;
					if (calc.model.contact_information.name) {
						this.model.contact_information.name = calc.model.contact_information.name;
					}
					if (calc.model.contact_information.phone) {
						this.model.contact_information.phone = calc.model.contact_information.phone;
					}
					if (calc.model.contact_information.email) {
						this.model.contact_information.email = calc.model.contact_information.email;
					}
				}
			});
			const rebate = getDiscount(this.cms);
			this.model.calculation.discount = rebate.discount;
			this.model.calculation.discountDisplay = rebate.discountDisplay;
		} else {
			await store.dispatch(BASKET_LOADED);
		}
		// check if discount has changed since last view of basket
		await store.dispatch(DISCOUNT_UPDATE);
		// this.handleDiscountChange();
		if (!(await initComponent(this))) {
			return;
		}

		setTimeout(() => {
			if (this.model.campaign.valid && this.model.campaign.hasContentPage) {
				this.nextStep(this.model.campaign.ID);
			} else {
				this.nextStep(this.cms.defaultCard);
			}
			store.state.showSpinner = false;
		}, this.cms.initialTimeout);

		store.state.showSpinner = true;
		this.componentInit = true;
	}

	public handlePrice(totalMonthlyPrice: number) {
		const rebate = getDiscount(this.cms);
		this.setSubtitle(rebate, totalMonthlyPrice);
	}

	public async gotoCard(cardName: string): Promise<boolean> {
		if (cardName === STEP.PAYMENT) {
			this.model.choosePackage.monthYear = undefined;
			this.model.payment.userSelected = false;
		}

		if (cardName === STEP.ORDER) {
			store.state.showSpinner = true;
			if (!this.isValid(this.model.currentCardName)) {
				store.state.showSpinner = false;
				return false;
			}
			this.mapOrderDescription = new MapOrderDescription(this as any);
			await this.mapOrderDescription.orderByEmail();
			// currentCard is being set by succes of orderByEmail
			return false;
		}

		this.model.currentCardName = cardName;
		return true;
	}

	/**
	 * when nextbtn is clicked - check for valid
	 * @param cardName
	 */
	public nextStep(cardName: string, addToHistory = true) {
		nextStep(cardName, addToHistory, this);
	}

	public getSubtitle(cardName: string) {
		return getSubtitle(cardName, this.model);
	}

	public isActiveCard(cardName: string): boolean {
		return cardName === this.model.currentCardName;
	}

	private setSubtitle(rebate: { discount: number; discountDisplay: string }, totalMonthlyPrice: number): void {
		let subtitle = '';
		if (rebate.discount < 1) {
			subtitle = this.cms.subtitleDiscount + rebate.discountDisplay;
		}
		if (totalMonthlyPrice) {
			if (subtitle !== '') {
				subtitle += ',';
			}
			subtitle += this.cms.subtitlePrice + Formatter.format(totalMonthlyPrice) + ' kr./md';
		}
		this.model.overview.subtitle = subtitle;
	}

	public isValid(cardName): boolean {
		switch (cardName) {
			case STEP.OVERVIEW:
				return store.getters.getSelectableGroup('basket').length > 1;
			case STEP.CONTACT_INFORMATION:
				let ok = this.model.contact_information.rki === 'nej' && isValidContactInfo(this.model, this.cms);
				if (!ok) {
					return false;
				}
				ok =
					this.model.contact_information.skader &&
					this.cms.damagesOk.includes(this.model.contact_information.skader);
				if (!ok) {
					return false;
				}
				const basket = store.getters.getSelectableGroup('basket');
				basket.forEach((product) => {
					if (ok && product.startsWith(InsurancesProductEnum.ULYKKES_FORSIKRING)) {
						const model = store.getters.getCalculator(product)?.model;
						if (model?.readyForBasket && model.personInfo.otherCustomerAge) {
							ok = Validator.isValidCpr(model.personInfo.otherCpr);
						}
					}
				});

				if (!ok) {
					return false;
				}

				let model;
				if (basket.includes(BasketProductsEnum.BOERNE_FAMILIE_FORSIKRING0)) {
					model = store.getters.getCalculator(BasketProductsEnum.BOERNE_FAMILIE_FORSIKRING0)?.model;
				} else if (basket.includes(BasketProductsEnum.BOERNEULYKKES_FORSIKRING0)) {
					model = store.getters.getCalculator(BasketProductsEnum.BOERNEULYKKES_FORSIKRING0)?.model;
				}
				if (!model?.readyForBasket) {
					// product not calculated
					return true;
				}
				if (model?.familyInfo?.personCountId > -1) {
					for (let i = 0; i <= model.familyInfo.personCountId; i++) {
						const cpr = 'cpr' + i;
						if (
							model.familyInfo[cpr] === undefined ||
							Validator.getAgeUnderEighteen(model.familyInfo[cpr]) < 0
						) {
							ok = false;
							i = model.familyInfo.personCountId;
						}
					}
				}

				return ok;

			case STEP.PAYMENT:
				return isValidPaymentInfo(this.model, this.cms);
			default:
				return true;
		}
	}

	public get showReceipt() {
		return getShowReceipt(this);
	}

	/** called by MapOrderDescription */
	public scrollToReceipt() {
		VueScrollTo.scrollTo(`#receipt`, 300, { easing: 'ease-out', offset: -25 });
	}
}
