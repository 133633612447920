import { HtmlBlock } from '@/cms/definitions/content-types';
import BuyInsuranceSettings from '../BuyInsuranceSettings';

export const productAccidentId = 'SS1VUF9VUC0yOA==';
export class FamilySettings extends BuyInsuranceSettings {
	// INDBO
	public sumsLabel: string;
	public sumsSelectDefault: string;
	public extendedBikeSum = '39392';
	// ULYKKE
	public work: Array<string> = [];
	public workIds: Array<string> = [];
	public personCountList: Array<string> = [];
	public showWorkForMinors = false;
	public workForMinorsLabel: string;
	public useAdultAccident = false;
	public familyMemberInfo: string;
	public minChildAge = 1;
	public childAgeSports = 4;
	public personCountPopupTitle: string;
	public personCountPopupContent: string;
	public childCprPopover: string;

	public constructor(settingsBlock: HtmlBlock) {
		super(settingsBlock);
		this.loadFamilySettings();
	}

	/**
	 * load settings from CMS if present
	 */
	private loadFamilySettings() {
		try {
			let setting: string;
			const settingsArr = this.settingsBlock.markup.split('$');
			settingsArr.forEach((setting_) => {
				setting = setting_.toLowerCase().trim();
				if (setting.indexOf('sumslabel=') > -1) {
					this.sumsLabel = this.getSettingsValue(setting_);
				} else if (setting.indexOf('sumsselectdefault=') > -1) {
					this.sumsSelectDefault = this.getSettingsValue(setting_);
				} else if (setting.startsWith('work=')) {
					this.work = this.getSettingsValue(setting_).split('¤');
				} else if (setting.startsWith('workids=')) {
					this.workIds = this.getSettingsValue(setting_).split('¤');
				} else if (setting.startsWith('personcountlist=')) {
					this.personCountList = this.getSettingsValue(setting_).split('¤');
				} else if (setting.startsWith('showworkforminors=')) {
					this.showWorkForMinors = JSON.parse(this.getSettingsValue(setting_));
				} else if (setting.startsWith('workforminorslabel=')) {
					this.workForMinorsLabel = this.getSettingsValue(setting_);
				} else if (setting.startsWith('useadultaccident=')) {
					this.useAdultAccident = JSON.parse(this.getSettingsValue(setting_));
				} else if (setting.indexOf('familymemberinfo=') > -1) {
					this.familyMemberInfo = this.getSettingsValue(setting_);
				} else if (setting.indexOf('extendedbikesum=') > -1) {
					this.extendedBikeSum = this.getSettingsValue(setting_);
				} else if (setting.startsWith('minchildage=')) {
					this.minChildAge = parseInt(this.getSettingsValue(setting_));
				} else if (setting.startsWith('childagesports=')) {
					this.childAgeSports = parseInt(this.getSettingsValue(setting_));
				} else if (setting.indexOf('personcountpopuptitle=') > -1) {
					this.personCountPopupTitle = this.getSettingsValue(setting_);
				} else if (setting.indexOf('personcountpopupcontent=') > -1) {
					this.personCountPopupContent = this.getSettingsValue(setting_);
				} else if (setting.indexOf('childcprpopover=') > -1) {
					this.childCprPopover = this.getSettingsValue(setting_);
				}
			});
		} catch (e) {
			console.error(e);
		}
	}
}
