import BuyInsuranceSettings from '../BuyInsuranceSettings';
import { cloneDeep } from 'lodash';
import { HtmlBlock } from '@/cms/definitions/content-types';

export const productId = 'SS1VUF9VUC0yOA==';

export class AccidentChildrenSettings extends BuyInsuranceSettings {
	public work: Array<string> = [];
	public workIds: Array<string> = [];
	public personCountList: Array<string> = [];
	public showWorkForMinors = false;
	public workForMinorsLabel: string;
	public minChildAge = 1;
	public childCprPopover: string;

	public constructor(settingsBlock: HtmlBlock) {
		super(settingsBlock);
		this.loadAccidentChildrenSettings();
	}

	/**
	 * load settings from CMS if present
	 */
	private loadAccidentChildrenSettings() {
		try {
			let setting: string;
			const settingsArr = this.settingsBlock.markup.split('$');
			settingsArr.forEach((setting_) => {
				setting = setting_.toLowerCase().trim();
				// console.log('checking setting', setting, setting.indexOf('usework='));

				if (setting.startsWith('work=')) {
					this.work = this.getSettingsValue(setting_).split('¤');
				} else if (setting.startsWith('workids=')) {
					this.workIds = this.getSettingsValue(setting_).split('¤');
				} else if (setting.startsWith('personcountlist=')) {
					this.personCountList = this.getSettingsValue(setting_).split('¤');
				} else if (setting.startsWith('showworkforminors=')) {
					this.showWorkForMinors = JSON.parse(this.getSettingsValue(setting_));
				} else if (setting.startsWith('workforminorslabel=')) {
					this.workForMinorsLabel = this.getSettingsValue(setting_);
				} else if (setting.startsWith('minchildage=')) {
					this.minChildAge = parseInt(this.getSettingsValue(setting_));
				} else if (setting.indexOf('childcprpopover=') > -1) {
					this.childCprPopover = this.getSettingsValue(setting_);
				}
			});
		} catch (e) {
			console.error(e);
		}
	}
}
