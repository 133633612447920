import { mapState } from 'vuex';
import FieldValidator from '@/services/fieldValidatorService';
import { HouseSettings } from '../../house/HouseSettings';
import ExistingAbProductsComponent from '../../components/ExistingABProductsComponent.vue';
import { nextTick, PropType } from 'vue';
import { CardBlock } from '@/cms/definitions/content-types';
import { CustomerCalculation } from '@/store/modules/calculatorContext';
import store from '@/store/store';
import InsurancesProductEnum from '@/enums/InsurancesProductEnum';
import { Options, Vue } from 'vue-class-component';
import { Validator } from '../../services/ValidationService';
import { mapDawaAddress, resetAddress } from '@/services/addressMappingService';

@Options({
	name: 'PersonInfoStepComponent',
	props: {
		card: Object as PropType<CardBlock>, // Card from cms
		isValid: Boolean, // wether card is complete
	},
	components: {
		ExistingAbProductsComponent,
	},
	computed: mapState<any>({
		model: (state) => state.calculatorContext[state.calculatorContext.active].model,
		cms: (state) => state.calculatorContext[state.calculatorContext.active].cms,
	}),
})
export default class PersonInfoStepComponent extends Vue {
	card: CardBlock; // Card from cms
	isValid: boolean; // wether card is complete

	public model!: any; // datastructure for user input (store)
	public cms!: HouseSettings; // settings from CMS (store)

	public fieldValidator: FieldValidator = Validator;
	public defaultAddress: string = null;
	public readonlyAge: string = null;
	public readonlyAddress: string = null;
	public holidayHouse = false;

	public created() {
		this.holidayHouse = this.model.productName === InsurancesProductEnum.FRITIDSHUS_FORSIKRING;

		const addressParam = this.$route.query.address as string;
		if (addressParam) {
			this.defaultAddress = addressParam;
			if (this.holidayHouse) {
				this.model.personInfo.holidayAddress.address = addressParam;
			}
		}
		const customerAgeParam = this.$route.query.customerAge as string;
		if (customerAgeParam) {
			this.model.personInfo.customerAge = customerAgeParam;
		}

		const almbrandCustomerParam = this.$route.query.almbrandCustomer as string;
		if (almbrandCustomerParam) {
			this.model.personInfo.almbrandCustomer = almbrandCustomerParam;
		}

		const existingAlmBrandProductsParam = this.$route.query.existingAlmBrandProducts as Array<string>;
		if (existingAlmBrandProductsParam) {
			this.model.personInfo.existingAlmBrandProducts = existingAlmBrandProductsParam;
		}
	}

	public mounted() {
		const customerCalculation: CustomerCalculation = store.getters.getCustomerCalculation;
		if (!this.model.personInfo.address || customerCalculation.hasCustomerData) {
			// wait for transition to begin showing address field
			setTimeout(() => {
				const input = document.querySelector('.vue3-autocomplete-container input') as any;
				if (input) {
					input.focus();
				}
			}, this.cms.animationTimeout + 500);
		}

		if (this.model.multipleProducts) {
			const commonModel = store.getters.getCalculatorCommonModel;
			if (commonModel.personInfo.address && !this.holidayHouse) {
				this.readonlyAddress = this.model.personInfo.address;
			}
			if (this.fieldValidator.isValidAge(commonModel.personInfo.customerAge)) {
				this.readonlyAge = this.model.personInfo.customerAge;
			}
		}
		if (
			!this.readonlyAge &&
			customerCalculation.hasCustomerData &&
			this.fieldValidator.isValidAge(customerCalculation?.customer?.personInfo?.customerAge)
		) {
			this.readonlyAge = customerCalculation.customer.personInfo.customerAge;
		}

		window.addEventListener('keyup', this.moveOn);
	}

	public beforeUnmount() {
		window.removeEventListener('keyup', this.moveOn);
	}

	public moveOn(evt) {
		if (evt.keyCode === 13 && this.isValid) {
			this.nextStep();
		}
	}

	public get validAddress(): boolean {
		console.log();

		return this.holidayHouse
			? this.model.personInfo.holidayAddress.address !== undefined
			: this.model.personInfo.address != undefined;
	}

	public nextStep() {
		this.$emit('nextStep', this.card.routePath);
	}

	public addressSearch(address) {
		if (address.matchText) {
			if (this.holidayHouse) {
				mapDawaAddress(this.model.personInfo.holidayAddress, address);
			} else {
				mapDawaAddress(this.model.personInfo, address);
			}

			this.model.pristineStep.set(this.card.name, true);
			nextTick(() => {
				// wait for transition to begin showing customerAge field
				if (!this.model.personInfo.customerAge) {
					setTimeout(() => {
						const input = document.getElementById('customerAge');
						if (input) {
							input.focus();
						}
					}, 300);
				} else {
					setTimeout(() => {
						(document.activeElement as any).blur();
					}, 300);
				}
			});

			return;
		}
		if (this.holidayHouse) {
			resetAddress(this.model.personInfo.holidayAddress);
		} else {
			resetAddress(this.model.personInfo);
		}
	}
}
