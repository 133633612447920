import { SELECTABLE_UPDATED } from '@/store/modules/selectableContext';
import OverviewCalculatorStepComponent from './OverviewCalculatorStepComponent.vue';
import { AccidentSettings } from '../../accident/AccidentSettings';
import { HomeSettings } from '../../home/HomeSettings';
import { HouseSettings } from '../../house/HouseSettings';
import { DogSettings } from '../../dog/DogSettings';
import { TravelSettings } from '../../travel/TravelSettings';
import { CarSettings } from '../../car/CarSettings';
import { BasketSettings } from '../BasketSettings';
import { Options, Vue } from 'vue-class-component';
import store from '@/store/store';
import { getSuggestedProducts } from '../../services/ProductService';
import { Formatter } from '../../services/ValidationService';
import { path } from '../../services/NavigationService';

@Options({
	name: 'OverviewBasketMiniStepComponent',
	components: {
		OverviewCalculatorStepComponent,
	},
})
export default class OverviewBasketMiniStepComponent extends Vue {
	public cms:
		| AccidentSettings
		| HomeSettings
		| HouseSettings
		| DogSettings
		| TravelSettings
		| CarSettings
		| BasketSettings; // settings from CMS (store)

	public updateProdKey = 'updateProdKey';
	public productNames: Array<string> = [];
	public suggest: { suggestedProducts: string; discountDisplay: string } = {
		suggestedProducts: undefined,
		discountDisplay: undefined,
	};
	public suggestText: string = null;
	public suggestText1: string = null;
	public hasPrices = false;
	private unsubscribe: Function;

	public beforeUnmount() {
		this.unsubscribe();
	}

	public async created() {
		this.cms = store.getters.getActiveCalculator?.cms || store.getters.getBasket?.cms;
		this.productNames = await store.getters.getSelectableGroup('basket');
		this.unsubscribe = store.subscribeAction((action, state) => {
			if (action.type === SELECTABLE_UPDATED && action.payload?.group === 'basket') {
				this.productNames = store.getters.getSelectableGroup('basket');
				this.updateProdKey += '1';
				this.setSuggestText();
			}
		});
		this.setSuggestText();
	}

	private setSuggestText() {
		if (this.cms.suggestTextMiniBasket) {
			this.suggestText = this.getSuggestText();
			this.suggestText1 = this.getSuggestText1();
		}
	}
	private getSuggestText(): string {
		if (!this.cms.suggestProductsText) {
			return undefined;
		}
		this.suggest = getSuggestedProducts(this.cms);
		if (!this.suggest) {
			return undefined;
		}
		return this.cms.suggestProductsText
			.replace('¤discount', this.suggest.discountDisplay)
			.replace('¤products', this.suggest.suggestedProducts);
	}
	private getSuggestText1(): string {
		if (this.suggest && this.cms.suggestProductsText1) {
			return this.cms.suggestProductsText1
				.replace('¤discount', this.suggest.discountDisplay)
				.replace('¤products', this.suggest.suggestedProducts);
		}
		return undefined;
	}

	public get showButtons(): boolean {
		// don't show when on basketPage
		return !this.$route.path.toLocaleLowerCase().includes('/forsikringer');
	}

	public get forsikringerLink() {
		//const url = urlService.ensureCorrectLink(path + 'forsikringer');
		const url = path + 'forsikringer';
		return url;
	}

	public get basketLink() {
		// const url = urlService.ensureCorrectLink(path + 'gotoproduct?product=kurv');
		const url = path + 'gotoproduct?product=kurv';
		return url;
	}

	public getMonthlyTotalPrice(monthly: boolean): string {
		let totalPrice = 0;
		this.productNames.forEach((productName) => {
			const calculator = store.getters.getCalculator(productName);
			if (calculator && calculator.model && calculator.model.readyForBasket) {
				if (monthly) {
					totalPrice += calculator.model.choosePackage.selectedPackage.totalPrice;
				} else {
					totalPrice += calculator.model.choosePackage.selectedPackage.yearlyPriceTotal;
				}
			}
		});
		if (totalPrice) {
			this.hasPrices = true;
			this.$emit('basketPrice', totalPrice);
			return Formatter.format(totalPrice) + ' kr.' + (monthly ? '/md' : '/år');
		}
		this.$emit('basketPrice', 0);
		this.hasPrices = false;
		return 'Ingen produkter beregnet endnu';
	}
}
