import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = ["keys"]
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _mergeProps(_ctx.$attrs, { class: _ctx.rootClass }), [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.model, (block, index) => {
      return _withDirectives((_openBlock(), _createElementBlock("div", {
        key: index,
        keys: block.id,
        class: _normalizeClass(_ctx.getBlockClasses(block))
      }, [
        (!block.hide)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.getComponentTypeForBlock(block)), {
                "data-epi-block-id": _ctx.isEditable ? block.contentLink.id : null,
                model: block
              }, null, 8, ["data-epi-block-id", "model"]))
            ]))
          : _createCommentVNode("", true)
      ], 10, _hoisted_1)), [
        [_vShow, !block.hide]
      ])
    }), 128))
  ], 16))
}