// export const applyCookieScript = (script?: string) => {
//     const id = 'CookieBanner';

//     if (!document.getElementById(id)) {
//         let cookieConsent = document.createElement('script');
//         let bannerScript = script ? script : "//policy.app.cookieinformation.com/uc.js";

//         cookieConsent.setAttribute('id', id);
//         cookieConsent.setAttribute('src', bannerScript);

//         document.head.appendChild(cookieConsent);
//     }
// }

export const getCookie = (cName: string) => {
	const cValue = document.cookie;
	const parts = cValue.split(';').map((x) => x.split('='));
	const x = parts.find((x) => x[0].trim() === cName);
	if (x !== undefined) {
		const [rawName, rawValue] = x;
		const name = rawName.trim();
		const value = rawValue.trim();
		return { name, value };
	}
	return null;
};
