import { TileBlock } from '@/cms/definitions/content-types';
import { trackSelectionGroupCta } from '@/services/trackingUtility';
import { SELECTABLE_UPDATED } from '@/store/modules/selectableContext';
import store from '@/store/store';
import { PropType } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';

@Options({
	name: 'TileButton',
	components: {},
	computed: mapState<any>({
		isEditable: (state) => state.epiContext.isEditable,
		parentModel: (state) => state.epiContent.model,
	}),
	props: {
		model: Object as PropType<TileBlock>,
	},
})
export default class TileButton extends Vue {
	model: TileBlock;

	public disabled = false;
	public showError = false;
	private unsubscribe: any;
	public inAction = false;

	beforeMount() {
		if (!this.model.buttonClass) {
			this.model.buttonClass = 'btn-solid-secondary';
		}

		if (this.model.selectGroupBtn) {
			this.unsubscribe = store.subscribeAction((action, state) => {
				if (action.type === SELECTABLE_UPDATED && action.payload?.group === this.model.selectGroupBtn) {
					this.toggleDisabled();
				}
			});
			// run on mount
			this.toggleDisabled();
		}
	}

	public beforeUnmount() {
		if (this.unsubscribe) {
			this.unsubscribe();
		}
	}

	private toggleDisabled() {
		const selectGroupBtn = store.getters.getSelectableGroup(this.model.selectGroupBtn); // selected in store
		if (!selectGroupBtn || selectGroupBtn.length === 0) {
			this.disabled = true;
		} else {
			this.disabled = false;
		}
		this.showError = false;
	}

	public click(): void {
		this.handleBtnAction();
	}

	private handleBtnAction() {
		if (!this.inAction) {
			this.inAction = true;
		} else {
			return;
		}

		if (!this.disabled) {
			trackSelectionGroupCta(this.model.selectGroupBtn);
			this.$router.push(this.model.relativeURL);
		} else {
			this.showError = true;
			this.inAction = false;
		}
	}
}
