import { mapState } from 'vuex';
import { CarSettings } from '../car/CarSettings';
import { CardBlock } from '@/cms/definitions/content-types';
import { AccidentSettings } from '../accident/AccidentSettings';
import { HomeSettings } from '../home/HomeSettings';
import { HouseSettings } from '../house/HouseSettings';
import { DogSettings } from '../dog/DogSettings';
import { TravelSettings } from '../travel/TravelSettings';
import FieldValidator from '@/services/fieldValidatorService';
import { Options, Vue } from 'vue-class-component';
import { Formatter, monthYearArray, Validator, ValuePair } from '../services/ValidationService';
import { FamilySettings } from '../family/FamilySettings';
import { trackPurchase, trackReceipt } from '@/services/trackingUtility';

@Options({
	name: 'PaymentStepComponent',
	components: {},
	computed: mapState<any>({
		model: (state) => state.calculatorContext[state.calculatorContext.active].model,
		cms: (state) => state.calculatorContext[state.calculatorContext.active].cms,
	}),
	props: {
		card: Object, // Card from cms
		isValid: Boolean, // wether card is complete
	},
})
export default class PaymentStepComponent extends Vue {
	card: CardBlock; // Card from cms
	isValid: boolean; // wether card is complete

	public model!: any; // datastructure for user input (store)

	public cms!:
		| DogSettings
		| TravelSettings
		| CarSettings
		| AccidentSettings
		| HomeSettings
		| HouseSettings
		| FamilySettings; // settings from CMS (store)

	public monthYearArray: Array<ValuePair> = monthYearArray;

	public monthlyDisplayPrice: string = null;
	public yearlyDisplayPrice: string = null;
	public fieldValidator: FieldValidator = Validator;
	public oneSubmit = false;

	public created() {
		const pack = this.model.choosePackage.selectedPackage;
		this.monthlyDisplayPrice = pack.totalPriceDisplay + ' pr. måned';
		this.yearlyDisplayPrice = pack.yearlyPriceTotalDisplay + ' årligt';
	}

	public mounted(): void {
		window.addEventListener('keyup', this.moveOn);
	}

	public beforeUnmount() {
		window.removeEventListener('keyup', this.moveOn);
	}

	public handleRadioClick(evt) {
		this.model.choosePackage.monthYear = evt.value;
		this.model.payment.userSelected = true;
	}

	public moveOn(evt) {
		if (evt.keyCode === 13 && this.isValid) {
			this.nextStep();
		}
	}

	public getYearlySavingsTxt() {
		if (this.model.choosePackage?.selectedPackage?.totalPrice) {
			const amount =
				this.model.choosePackage.selectedPackage.totalPrice * 12 -
				this.model.choosePackage.selectedPackage.yearlyPriceTotal;
			const res = Formatter.format(amount) + ' kr.';
			return this.cms.yearlySavings ? this.cms.yearlySavings.replace('¤yearlySaving', res) : '';
		}
		return '';
	}

	public async nextStep() {
		if (!this.isValid) {
			this.model.pristineStep.set(this.card.name, false);
		}
		if (!this.oneSubmit && this.isValid) {
			this.oneSubmit = true;
			await trackPurchase();
			await trackReceipt();
			this.$emit('nextStep', this.card.routePath);
		}
	}
}
