import { Options, Vue } from 'vue-class-component';
import { isThemed } from '@/services/themeService';
import { isApp } from '@/services/environmentService';

@Options({
	name: 'CookieFooter',
	components: {},
})
export default class CookieFooter extends Vue {
	public get isThemed() {
		return isThemed();
	}

	public isLoggedInAsApp() {
		return isApp();
	}
}
