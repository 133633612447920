import InsurancesProductEnum from '@/enums/InsurancesProductEnum';
import { resetAddress } from '@/services/addressMappingService';
import store from '@/store/store';

export const isSyncWithCommonModel = (comp): { ok: boolean; msg?: string } => {
	if (!comp.model.multipleProducts) {
		return { ok: true };
	}

	if (
		comp.model.productName === InsurancesProductEnum.HUS_FORSIKRING ||
		comp.model.productName === InsurancesProductEnum.FRITIDSHUS_FORSIKRING ||
		comp.model.productName === InsurancesProductEnum.INDBO_FORSIKRING ||
		comp.model.productName === InsurancesProductEnum.BIL_FORSIKRING ||
		comp.model.productName === InsurancesProductEnum.BOERNE_FAMILIE_FORSIKRING
	) {
		const personInfoCommon = store.getters.getCalculatorCommonModel?.personInfo;
		if (!personInfoCommon) {
			return { ok: true };
		}
		const personInfoCalc = comp.model.personInfo;
		if (!compareField('zipCode', personInfoCommon, personInfoCalc)) {
			resetAddress(personInfoCalc);
			return {
				ok: false,
				msg: personInfoCommon.zipCode + ' ' + personInfoCommon.zipName,
			};
		}
	}
	return { ok: true };
};

/***** private methods *****************/
const compareField = (fieldName: string, commonModel: any, calculatorModel: any): boolean => {
	return commonModel[fieldName] ? calculatorModel[fieldName] === commonModel[fieldName] : true;
};
