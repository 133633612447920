import { mapState } from 'vuex';
import FieldValidator from '@/services/fieldValidatorService';
import { CardBlock } from '@/cms/definitions/content-types';
import { DogSettings } from '../DogSettings';
import ExistingAbProductsComponent from '../../components/ExistingABProductsComponent.vue';
import { Options, Vue } from 'vue-class-component';
import { PropType } from 'vue';
import { Validator } from '../../services/ValidationService';

@Options({
	name: 'PersonInfoStepComponent',
	components: {
		ExistingAbProductsComponent,
	},
	computed: mapState<any>({
		model: (state) => state.calculatorContext[state.calculatorContext.active].model,
		cms: (state) => state.calculatorContext[state.calculatorContext.active].cms,
	}),
	props: {
		card: Object as PropType<CardBlock>,
		isValid: Boolean,
	},
})
export default class PersonInfoStepComponent extends Vue {
	card: CardBlock; // Card from cms
	isValid: boolean; // whether card is complete

	public model!: any; // datastructure for user input (store)
	public cms!: DogSettings; // settings from CMS (store)

	public fieldValidator: FieldValidator = Validator;

	public mounted() {
		window.addEventListener('keyup', this.moveOn);
	}

	public beforeUnmount() {
		window.removeEventListener('keyup', this.moveOn);
	}

	public moveOn(evt) {
		if (evt.keyCode === 13 && this.isValid) {
			this.nextStep();
		}
	}

	public nextStep() {
		this.$emit('nextStep', this.card.routePath);
	}
}
